import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nombre: "",
  puntos: 0,
  mesasEvaluadas: 0,
  foto: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.nombre = action.payload.nombre || "" ;
      state.puntos = action.payload.puntos || 0;
      state.mesasEvaluadas = action.payload.mesasEvaluadas || 0;
      state.foto = action.payload.foto || "";
    },
    deleteUserData: (state) => {
      state.nombre = "";
      state.puntos = 0;
      state.mesasEvaluadas = 0;
      state.foto = "";
    }
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;

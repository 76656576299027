export const swipeLeftAnim = {
  animation: "swipeLeft 0.5s cubic-bezier(0.39, 0.58, 0.57, 1) forwards",
  animationDelay: "0.3s",
  "@keyframes swipeLeft": {
    "0%": {
      transform: "translateX(0) rotate(0)",
      opacity: 1
    },
    "100%": {
      transform: "translateX(-1000px) rotate(-30deg)",
      opacity: 1
    },
  },
};

export const swipeRightAnim = {
  animation: "swipeRight 0.5s cubic-bezier(0.39, 0.58, 0.57, 1) forwards",
  animationDelay: "0.3s",
  "@keyframes swipeRight": {
    "0%": {
      transform: "translateX(0) rotate(0)",
      opacity: 1
    },
    "100%": {
      transform: "translateX(+1000px) rotate(30deg)",
      opacity: 1
    },
  },
};

import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#87CEEB', // Your primary color
    },
    secondary: {
      main: '#EBA487', // Your secondary color
    },

    primarySoftContrast: {
      main: "#8b3dff",
      dark: "#0D47A1"

    },
    text: {
      primary: grey[900], // or any other grey shade
      secondary: grey[600],
      disabled: grey[400],
    },

    error: {
      main: '#ff0000', // Your error color
    },
    // You can also define background, text colors, etc.
  },
  typography: {
    h1: {
      fontSize: '2em', // Example size
    },
    h2: {
      fontSize: '1.5em', // Example size
    },
    body1: {
      fontSize: '1em', // Standard size for body text
    },

    body2: {
      fontSize: '0.875rem', // Slightly smaller than body1
    },
  },
  // You can also customize other theme properties like typography
});

export default theme;

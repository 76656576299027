import axios from "axios";
import api from "./api";
import { SITIO_OFICIAL_SUBDOMINIOS } from "../utils/constants";

export const getMesasRandom = async (modo, currentStack) => {
  try {
    const response = await api.post("/private/mesas/get/random", {modo, currentStack});
    return {
      error: false,
      response: response.data,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return {
        error: true,
        response: "Error al buscar mesas para evaluar.",
      };
    } else {
      return {
        error: true,
        response: "Error al buscar mesas para evaluar.",
      };
    }
  }
};

export const voteMesa = async (mesaId, isMesaError) => {
  
  try {
    const response = await api.post("/private/mesas/vote", {mesaId,isMesaError});
    console.log("Response from mesaSerice -> vote", response.data);
    return {
      error: false,
      response: response.data,
    };
  } catch (error) {
    console.log("Response from mesaSerice -> vote = error", error);
    return {
      error: true,
      response: "Error al votar la mesa " + mesaId,
    };
    
  }
  
}

export const getTelegramaImageURL = async (mesaID) => {
  try {
    const resp = await fetch(
      `https://${SITIO_OFICIAL_SUBDOMINIOS.current}resultados.gob.ar/backend-difu/scope/data/getTiff/${mesaID}`
    );
    const data = await resp.json();
    const base64EncodedData = data.encodingBinary;
    const binaryString = atob(base64EncodedData);
    const len = binaryString.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binaryString.charCodeAt(i);
    }
    // Use the ArrayBuffer with TIFF.js
    const tiff = new window.Tiff({ buffer: buffer });
    const canvas = tiff.toCanvas();
    return canvas.toDataURL();
  } catch (error) {
    console.log("Error generando la imagen del telegrama", error);
    return "";
  }
};

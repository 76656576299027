import React from "react";
import { useDispatch } from "react-redux";
import { Container } from "@mui/material";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import MainLayout from "../../components/layout/MainLayout";
import AuthForm from "../../components/auth/AuthForm";
import { createUser } from "../../services/userService";
import { userActions } from "../../redux/userSlice";

const Register = ({ crearCuenta }) => {
  const dispatch = useDispatch()

  const signUpWithEmail = async (data) => {
    try {
      await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      // Create user in the backend
      const userServiceRes = await createUser({ nombre: data.nombre });
      console.log("userRes", userServiceRes);
      if(!userServiceRes.error){
        dispatch(userActions.setUserData(userServiceRes.response))
      }
    } catch (error) {
      console.error("Error al inciiar sesion: ", error);
      console.error("Mensaje: ", error.message);
      console.error("Mensaje: ", error.code);
      if(error.code == "auth/email-already-in-use"){
        // TODO: Mostrar notification
        console.log("Ya existe un usuario con ese email. Incia sesion.");
      }
    }
  };

  const loginWithEmail = async (data) => {
    try {
      await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

    } catch (error) {
      console.error(error);
      console.log("Error al iniciar sesion:", error);
      if(error.code == "auth/invalid-login-credentials"){
        // TODO: Mostrar notification
        console.log("El email y/o la constraseña son incorrectos");
      }
      
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const gmailUserCredential = await signInWithPopup(auth, provider);
      console.log("GMAIL USER", gmailUserCredential);
      const details = await getAdditionalUserInfo(gmailUserCredential);
      console.log("GMAIL USER DETAILS", details);
      // Check if it's the first time the user is logging in with Google
      if (details.isNewUser) {
        // Create user in the backend
        console.log("Usuario GMAIL nuevo");
        const userRes = await createUser({ nombre: details.profile.given_name });
        console.log("userRes GMAIL", userRes);
      } else {
        console.log("Usuario GMAIL existente");
        // Handle returning user logic
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainLayout>
      <Container maxWidth="md">
        <AuthForm
          crearCuenta={crearCuenta}
          onEmailAuth={crearCuenta? signUpWithEmail : loginWithEmail}
          onGmailAuth={signInWithGoogle}
        />
      </Container>
    </MainLayout>
  );
};

export default Register;

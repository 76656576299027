import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import MesaCard from './MesaCard';


const MesasStack = ({ mesasDataArray }) => {
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {mesasDataArray.map((mesaData, index) => {
        const zIndex = mesasDataArray.length - index;
        const yOffset = index * 4; // Adjust this value for the desired overlap
        return (
          <Box
            key={mesaData.id}
            sx={{
              display: index > 5 ? "none": "block",
              position: 'absolute',
              top: `${yOffset}px`,
              left: 0,
              right: 0,
              zIndex, // Stack with higher indices at the bottom
              width: '100%', // Ensure each MesaCard has the same width
              height: '100%', // Ensure each MesaCard has the same height
              // Adjust scale or any other transform if needed
              transition: 'transform 0.3s, top 0.3s', // Smooth transition for transform and top
            }}
          >
            <MesaCard  mesaData={mesaData} />
          </Box>
        );
      })}
    </Box>
  );
};

export default MesasStack;

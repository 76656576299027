// NavProfile.js
import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import styles from "./NavProfile.module.css"; // Importing the CSS module
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';


function NavProfile({ onCerrarSesion }) {
  const navigate = useNavigate();
  const userNombre = useSelector((state) => state.user.nombre);
  const userFoto = useSelector((state) => state.user.foto);

  const hasFoto = userFoto && userFoto != "";
  const userInicial = userNombre.charAt(0).toUpperCase();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCerrarSesion = () => {
    onCerrarSesion();
    handleClose();
  };

  const handleGoToProfile = () => {
    handleClose()
    navigate('/perfil');

  }

  return (
    <Box className={styles.profileBox}>
      <Tooltip title="Perfil">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            alt={userNombre}
            src={hasFoto ? userFoto : undefined}
            className={styles.avatar}
          >
            {userInicial}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleGoToProfile}>
          <Avatar /> {userNombre || ""}
        </MenuItem>
        <MenuItem onClick={handleGoToProfile}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          Editar Perfil
        </MenuItem>

        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Invitar amigos
        </MenuItem>
        <MenuItem onClick={handleCerrarSesion}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default NavProfile;

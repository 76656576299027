// src/components/MesaCard.js

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Box, Grid, Typography, Link, CircularProgress } from "@mui/material";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import {
  LAYOUT_DIMENSIONS,
  SITIO_OFICIAL_SUBDOMINIOS,
  SWIPING_DIR,
} from "../../utils/constants";
import { swipeLeftAnim, swipeRightAnim } from "../../utils/animations";
import { getTelegramaImageURL } from "../../services/mesasService";
import MesaTablaPartidos from "./MesaTablaPartidos";
import { addSubdomain } from "../../utils/misc";
import { useTheme } from "@emotion/react";
import styles from "./MesaCard.module.css";
import useScreenWidth from "../../hooks/useScreenWidth";
import MesaTablaOtros from "./MesaTablaOtros";
import WrapInCarouselIfTrue from "../layout/ConditionalWrappers/WrapInCarouselIfTrue";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import TelegramaImagen from "./TelegramaImagen";
import { setZoomInitState } from "../../redux/uiSlice";

const MesaCard = ({ mesaData }) => {

  const dispatch = useDispatch();


  const [imageSrc, setImageSrc] = useState("");
  const [animation, setAnimation] = useState({});

  // Telegrama Image zoom state
  const zoomInitial = useSelector((state) => state.ui.zoomInitialState);
  const [localZoomState, setLocalZoomState] = useState(zoomInitial)
  
  const handleZoomChange = (scale, x, y) => {
    setLocalZoomState({scale, x, y})
  }


  const currentTopMesaId = useSelector((state) => state.mesas.currentTopMesaId);
  const swiping = useSelector((state) => state.ui.swiping);
  const swipingDirection = useSelector((state) => state.ui.swipingDirection);
  const isTopCard = mesaData.id === currentTopMesaId;
  const shouldSwipeLeft =
    swiping && swipingDirection == SWIPING_DIR.left && isTopCard;
  const shouldSwipeRight =
    swiping && swipingDirection == SWIPING_DIR.right && isTopCard;

  const { screenWidth, isMobile } = useScreenWidth();

  const theme = useTheme();

  // INITAL SETUP
  useEffect(() => {
    // GET IMAGE
    const getImage = async () => {
      const telegramaURL = await getTelegramaImageURL(mesaData.id);
      setImageSrc(telegramaURL);
    };
    getImage();

  }, []);

  // SWIPE
  useEffect(() => {
    if (shouldSwipeLeft) {
      dispatch(setZoomInitState(localZoomState))
      setAnimation(swipeLeftAnim);
    } else if (shouldSwipeRight) {
      dispatch(setZoomInitState(localZoomState))
      setAnimation(swipeRightAnim);
    } else {
      setAnimation({});
    }
  }, [shouldSwipeLeft, shouldSwipeRight]);

  const topSpacing =
    LAYOUT_DIMENSIONS.headerHeight +
    LAYOUT_DIMENSIONS.headerBottomMargin +
    LAYOUT_DIMENSIONS.mesaCardMargin * 2.5;

  const mesaLink = addSubdomain(
    mesaData.link,
    SITIO_OFICIAL_SUBDOMINIOS.current
  );

  return (
    <Card
      className={styles.card}
      sx={{
        margin: `${LAYOUT_DIMENSIONS.mesaCardMargin}px`,
        boxShadow: 3,
        ...animation,
      }}
    >
      <Box className={styles.boxData}>
        <CardContent sx={{ flex: "1 0 auto" }} className={styles.cardContent}>
          <Typography variant="h5">
            Mesa <b>{mesaData.id}</b>
          </Typography>
          <Typography style={{ marginBottom: "15px" }}>
            <Link
              href={mesaLink}
              color={theme.palette.primarySoftContrast.main}
              target="_blank"
            >
              Ver en sitio oficial
            </Link>
          </Typography>
          {isMobile && (
            <Typography className={styles.deslizaTip}>
              <i>Deslizar para ver mas resultados</i>
            </Typography>
          )}
          <WrapInCarouselIfTrue condition={isMobile}>
            <MesaTablaPartidos
              resultadosPositivos={mesaData.resultados.positivos}
            />
            <MesaTablaOtros
              resultados={mesaData.resultados}
              className={`${styles.tablaOtros} ${styles.tablaOtrosLeft}`}
              isFirstGroup
            />
            <MesaTablaOtros
              resultados={mesaData.resultados}
              className={`${styles.tablaOtros} ${styles.tablaOtrosRight}`}
            />
          </WrapInCarouselIfTrue>
        </CardContent>
        {shouldSwipeLeft && !isMobile && (
          <img
            className={`${styles.swipe} ${styles.swipeLeft}`}
            src={process.env.PUBLIC_URL + "/img/Estampa OK.png"}
          />
        )}
      </Box>
      <Box className={styles.boxImagen}>
        {imageSrc == ""? <div style={{width:"100%", textAlign:"center", height: `calc(100vh - ${topSpacing}px)`}}><CircularProgress/></div>:(
          <TransformWrapper 
          minScale={0}
          initialScale={0.2}
          initialPositionX={13}
          initialPositionY={25}
          limitToBounds={false}>
            <TransformComponent wrapperStyle={{overflow: "visible", height: `calc(100vh - ${topSpacing}px)` }}>
              <img src={imageSrc} alt="Telegrama" />
            </TransformComponent>
          </TransformWrapper>
        )}
        {shouldSwipeRight && !isMobile && (
          <img
            className={`${styles.swipe} ${styles.swipeRight}`}
            src={process.env.PUBLIC_URL + "/img/Estampa ERROR.png"}
          />
        )}
      </Box>
    </Card>
  );
};

export default MesaCard;

// src/components/layout/MainLayout.js

import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box } from "@mui/material";
import { LAYOUT_DIMENSIONS } from "../../utils/constants";
import StyledBox from "../ui/StyledBox";

const MainLayout = ({ children, onKeyDown, tabIndex, containerRef }) => {
  const topSpacing =
    LAYOUT_DIMENSIONS.headerHeight + LAYOUT_DIMENSIONS.headerBottomMargin;
  return (
    <>
      <Header />
      <StyledBox
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        component="main"
        ref={containerRef}
        sx={{
          marginTop: `${LAYOUT_DIMENSIONS.headerBottomMargin}px`,
          minHeight: `calc(100vh - ${topSpacing}px)`,
        }}
      >
        {children}
      </StyledBox>
      <Footer />
    </>
  );
};

export default MainLayout;

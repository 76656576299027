import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";

const RouterButton = ({ linkTo,mainclass, activeclass, children, ...otherProps }) => {
  const location = useLocation();

  const activeClass = location.pathname == linkTo ? activeclass : ""

  console.log(location.pathname); // This will log the current path
  return (
    <Button className={`${mainclass} ${activeClass}`} {...otherProps}>
      <Link to={linkTo} style={{ textDecoration: "none", color: "inherit" }}>
        {children}
      </Link>
    </Button>
  );
};

export default RouterButton;

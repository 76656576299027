import React, { useEffect, useRef, forwardRef } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledBox = styled(
  forwardRef((props, ref) => <Box ref={ref} {...props} />)
)({
  '&:focus': {
    outline: 'none',
  },
});


export default StyledBox
import React, { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import MainLayout from "../../components/layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../services/userService";
import { userActions } from "../../redux/userSlice";

const Perfil = () => {
  const dispatch = useDispatch()
  const nombre = useSelector((state) => state.user.nombre);
  const puntos = useSelector((state) => state.user.puntos);
  const mesasEvaluadas = useSelector((state) => state.user.mesasEvaluadas);


  useEffect(() => {
    // Update user data
    const updateUserData = async () => {
      const userServiceRes = await getUserData();
      if (!userServiceRes.error) {
        dispatch(userActions.setUserData(userServiceRes.response));
      }

      updateUserData()
    };
  }, []);

  return (
    <MainLayout>
      <Container maxWidth="md">
        <Typography variant="h1">{nombre}</Typography>
        <Typography variant="h5">
          <b>Puntos {puntos}</b>
        </Typography>
        <ul style={{ fontSize: "small", marginBottom: "15px" }}>
          <li>
            <Typography>
              <span style={{ color: "green" }}>
                <b>+1 punto</b>
              </span>{" "}
              por mesa evaluada.{" "}
            </Typography>
          </li>
          <li>
            <Typography>
              <span style={{ color: "green" }}>
                <b>+1.000 puntos</b>
              </span>{" "}
              por mesa veridicada como tu evaluación.
            </Typography>
          </li>
          <li>
            <Typography>
              <span style={{ color: "red" }}>
                <b>-100 puntos</b>
              </span>{" "}
              por evaluación incorrecta.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5">Mesas evaluadas: {mesasEvaluadas}</Typography>
      </Container>
    </MainLayout>
  );
};

export default Perfil;

import * as React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useSelector } from "react-redux";
import RouterButton from "../ui/RouterButton";
import { Link, useLocation } from "react-router-dom";
import styles from './Header.module.css';
import NavProfile from "./NavProfile/NavProfile";

const Header = () => {
  const userLoggedIn = useSelector((state) => state.auth.loggedIn);
  const location = useLocation();

  const isInMesas = location.pathname == "/mesas"

  // Hanlders
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      // Additional logic after successful sign out (e.g., updating state, redirecting, etc.)
    } catch (error) {
      console.error("Error signing out: ", error);
      // Handle any errors that occur during sign out
    }
  };


  const theme = useTheme();
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" className={styles.logo}> Pueblo Fiscal</Link>
        </Typography>
        {/* Navegacion escondida para el MVP
        <RouterButton mainclass={styles.navLink} activeclass={styles.navLinkActive} linkTo="/estadisticas" variant="text">
          Estadísticas
        </RouterButton>
        <RouterButton mainclass={styles.navLink} activeclass={styles.navLinkActive} linkTo="/tabla" variant="text">
          Tabla de Posiciones
        </RouterButton>
        <RouterButton mainclass={styles.navLink} activeclass={styles.navLinkActive} linkTo="/mesas-verificadas" variant="text">
          Mesas Verificadas
        </RouterButton> */}

        {userLoggedIn && !isInMesas && <RouterButton
          variant=""
          linkTo="/mesas"
          style={{
            borderColor: "black",
            color: "white",
            backgroundColor: "#0D47A1"//theme.palette.primarySoftContrast.main,
            
          }}
        >
          Evaluar Mesas
        </RouterButton>}
        
        {!userLoggedIn && <RouterButton
          variant="outlined"
          linkTo="/iniciar-sesion"
          style={{
            marginRight: "10px",
            borderColor: "black",
            color: "black",
          }}
        >
          Iniciar Sesion
        </RouterButton>}
        {!userLoggedIn && <RouterButton
         linkTo="/crear-cuenta"
          style={{
            backgroundColor: theme.palette.primarySoftContrast.main,
            color: "white",
          }}
        >
          CREAR CUENTA
        </RouterButton>}
        {userLoggedIn && <NavProfile onCerrarSesion={handleLogout} />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

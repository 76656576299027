import axios from "axios";
import api from "./api";

export const createUser = async (userData) => {
  try {
    console.log("User data: ", userData);
    const response = await api.post("/private/users/create", userData);
    console.log("Response from userSerice", response);
    return {
      error: false,
      response: response.data,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return {
        error: true,
        response: "Error al crear usuario.",
      };
    } else {
      console.log("ERROR al crear usuario: ", error.message);
      return {
        error: true,
        response: "Error al crear usuario.",
      };
    }
  }
};

export const getUserData = async () => {
  try {
    const response = await api.get("/private/users/current");
    console.log("Response from get userService", response.data);
    return {
      error: false,
      response: response.data,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return {
        error: true,
        response: "Error al crear usuario.",
      };
    } else {
      console.log("ERROR al traer la data del usuario: ", error.message);
      return {
        error: true,
        response: "Error al iniciar sesion.",
      };
    }
  }

}

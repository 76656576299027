import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import uiReducer from './uiSlice';
import authReducer from './authSlice';
import mesasReducer from './mesasSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    ui: uiReducer,
    auth: authReducer,
    mesas: mesasReducer
  },
});

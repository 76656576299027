import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NonLoggedInRoute = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.loggedIn);  const location = useLocation();

  if (isAuthenticated) {
    // Redirect to the dashboard if the user is already authenticated
    return <Navigate to="/mesas" state={{ from: location }} replace />;
  }

  return children;
};


export default NonLoggedInRoute;
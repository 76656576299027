import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  swiping: false,
  swipingDirection: "",
  zoomInitialState: {
    scale: 0.2,
    x: 13,
    y: 25
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    startSwiping: (state, action) => {
      state.swiping = true;
      state.swipingDirection = action.payload;
    },
    stopSwping: (state, action) => {
      state.swiping = false;
      state.swipingDirection = "";
    },
    setZoomInitState: (state, action) => {
      state.zoomInitialState = action.payload
    }
  },
});

export const { setCurrentTopMesa, startSwiping,  stopSwping, setZoomInitState} = uiSlice.actions;

export default uiSlice.reducer;

import React from "react";
import { useForm } from "react-hook-form";
import { TextField, Button, Grid, Typography, Paper, Divider } from "@mui/material";
import GoogleButton from "react-google-button";
import { Link } from "react-router-dom";
import styles from "./AuthForm.module.css";

const AuthForm = ({ crearCuenta, onEmailAuth, onGmailAuth }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Paper
    className={styles.container}
      sx={{ boxShadow: 3}}
    >
      <form onSubmit={handleSubmit(onEmailAuth)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GoogleButton
              style={{ margin: "auto" }}
              onClick={onGmailAuth}
              label={crearCuenta ? "Crear cuenta con Google": "Iniciar sesión con Google"}
            />
          </Grid>
          <Grid item xs={12} style={{marginTop: "15px"}}>
            <Divider><Typography> O {crearCuenta ? "creá una cuenta": "iniciá sesión"} con tu email:</Typography></Divider> 

          </Grid>
          {crearCuenta && (<Grid item xs={12}>
            <TextField
              label="Nombre"
              variant="outlined"
              fullWidth
              {...register("nombre", { required: "El nombre es requerido." })}
              error={!!errors.nombre}
              helperText={errors.nombre?.message}
            />
          </Grid>) }
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              {...register("email", { required: "El email es requerido." })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
           <Grid item xs={12}>
              <TextField
                label="Contraseña"
                type="password"
                variant="outlined"
                fullWidth
                {...register("password", {
                  required: "La constraseña es requerida.",
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
            >
              {crearCuenta ? "Crear Cuenta" : "Iniciar Sesion"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{textAlign: "center"}}>
              {crearCuenta ? "Ya" : "No"} tenés una cuenta? <Link to={crearCuenta ? "/iniciar-sesion" : "/crear-cuenta"}>
                <b>{crearCuenta ? "Iniciá sesion" : "Crea una cuenta"} aquí</b>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AuthForm;

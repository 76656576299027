import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTopMesaId: "",
  mesasArray: [],
  cargaInicialEnCurso: false,
  cargaInicialCompleta: false
};

const mesasSlice = createSlice({
  name: "mesas",
  initialState,
  reducers: {
    setInicial: (state, action) => {
      state.mesasArray = action.payload;
      state.currentTopMesaId = action.payload[0].id;
    },
    nextInStack: (state) => {
      state.mesasArray = state.mesasArray.filter(
        (mesa) => mesa.id !== state.currentTopMesaId
      );
      if(state.mesasArray.length > 0){
        state.currentTopMesaId = state.mesasArray[0].id;
      }else{
        console.log("No hay mas mesas");
      }
      
    },
    appendToStack: (state, action) => {
      state.mesasArray = state.mesasArray.concat(action.payload);
    },
    cargaInicialStarted: (state) => {
      state.cargaInicialEnCurso = true
    },
    cargaInicialFinished: (state) => {
      state.cargaInicialCompleta = true
    }
  },
});

export const mesasActions = mesasSlice.actions;
export default mesasSlice.reducer;

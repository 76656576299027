import React, {useEffect, useRef, useState } from "react";
import { Container, Box, Typography, CircularProgress } from "@mui/material";
import MainLayout from "../../components/layout/MainLayout";
import SwipeButton from "../../components/mesa/SwipeButton";
import MesasStack from "../../components/mesa/MesasStack";
import {
  LAYOUT_DIMENSIONS,
  REQ_MESAS_MODOS,
  SWIPING_DIR,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { startSwiping, stopSwping } from "../../redux/uiSlice";
import { getMesasRandom, voteMesa } from "../../services/mesasService";
import { mesasActions } from "../../redux/mesasSlice";
import useScreenWidth from "../../hooks/useScreenWidth";
import styles from './Mesas.module.css';


const Mesas = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const userLoggedIn = useSelector((state) => state.auth.loggedIn);
  const mesaDataArray = useSelector((state) => state.mesas.mesasArray);
  const currentTopMesaId = useSelector((state) => state.mesas.currentTopMesaId);
  const cargaInicialEnCurso = useSelector(
    (state) => state.mesas.cargaInicialEnCurso
  );
  const cargaInicialCompleta = useSelector(
    (state) => state.mesas.cargaInicialCompleta
  );

  const [cargandoPrimerLote, setCargandoPrimerLote] = useState(false);
  
  const {screenWidth, isMobile} = useScreenWidth();
  console.log("IS MOBILE: ", isMobile);

    useEffect(() => {
      // Hacer focus en el container
      if (containerRef.current) {
        console.log("haciendo foco");
        containerRef.current.focus();
      }else{
        console.log("no hay container");
      }
      
    }, [containerRef])

  useEffect(() => {
    console.log("USE EFECT, logged in?", userLoggedIn);
    setCargandoPrimerLote(true);
    if (userLoggedIn && !cargaInicialEnCurso) {
      dispatch(mesasActions.cargaInicialStarted());
      // Traer mesas para evaluar
      const trearMesas = async () => {
        let mesasIniciales = [];
        const mesasResInicial = await getMesasRandom(REQ_MESAS_MODOS.inicial);
        if (mesasResInicial.error) {
          console.log("ERROR Mesas Inicial: ", mesasResInicial.response);
        } else {
          console.log("MESAS Inicial: ", mesasResInicial.response);
          mesasIniciales = mesasResInicial.response;
          dispatch(mesasActions.setInicial(mesasResInicial.response));
          const mesasResAnex = await getMesasRandom(
            REQ_MESAS_MODOS.anexo,
            mesasIniciales
          );
          if (mesasResAnex.error) {
            console.log("ERROR Mesas Anex: ", mesasResAnex.response);
          } else {
            console.log("Mesas Anex: ", mesasResAnex.response);
            mesasIniciales = mesasIniciales.concat(mesasResAnex.response);
            dispatch(mesasActions.appendToStack(mesasResAnex.response));
            const mesasResLote = await getMesasRandom(
              REQ_MESAS_MODOS.lote,
              mesasIniciales
            );
            if (mesasResLote.error) {
              console.log("ERROR Mesas Lote: ", mesasResLote.response);
            } else {
              console.log("Mesas Lote: ", mesasResLote.response);
              dispatch(mesasActions.appendToStack(mesasResLote.response));
              dispatch(mesasActions.cargaInicialFinished());
            }
          }
        }
      };

      console.log("Trayendo mesas...");
      trearMesas();
    }
  }, []);

  useEffect(() => {
    const traerMasMesas = async () => {
      console.log("Trayendo mas mesas...");
      const mesasResExtra = await getMesasRandom(
        REQ_MESAS_MODOS.lote,
        mesaDataArray
      );
      if (mesasResExtra.error) {
        console.log("ERROR Mesas Extra: ", mesasResExtra.response);
      } else {
        console.log("MESAS Extra: ", mesasResExtra.response);
        dispatch(mesasActions.appendToStack(mesasResExtra.response));
      }
    };

    setCargandoPrimerLote(mesaDataArray.length==0);

    console.log(
      `Quedan ${mesaDataArray.length} mesas (Carga Inicial completa: ${cargaInicialCompleta})`
    );
    if (
      cargaInicialCompleta &&
      (mesaDataArray.length == 8 || mesaDataArray.length == 0)
    ) {
      traerMasMesas();
    }
  }, [mesaDataArray]);

  // Handlers for OK and ERROR actions
  const handleSwipe = (isError) => {
    console.log(`${isError ? "Error" : "OK"} clicked!`);

    // Enviar voto al backend
    voteMesa(currentTopMesaId, isError)
      .then((serviceResult) => {
        if (serviceResult.error) {
          // TODO: Mostrar error
        } else {
          // ?? MOstrar notification ok?
          console.log("Mesas.jsx > handleSwipe", serviceResult.response);
        }
      })
      .catch((error) => {
        console.error("Error in voteMesa:", error);
      });

    // Animacion
    dispatch(startSwiping(isError ? SWIPING_DIR.right : SWIPING_DIR.left));

    // Remove mesa after the effect finished
    setTimeout(() => {
      dispatch(stopSwping());
      dispatch(mesasActions.nextInStack());
    }, 700);
  };

  // Trigger Swipe with keys
  function handleKeyPress(event) {
    if (event.key === " ") {
      event.preventDefault();
      handleSwipe(false);
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleSwipe(true);
    }
  }

  const topSpacing =
    LAYOUT_DIMENSIONS.headerHeight + LAYOUT_DIMENSIONS.headerBottomMargin;
  return (
    <MainLayout onKeyDown={handleKeyPress} tabIndex="0" containerRef={containerRef}>
      <Typography variant="h1">Evaluar Mesas</Typography>
      <Container
        className={styles.container}
        maxWidth="lg"
        sx={{
          height: `calc(100vh - ${topSpacing}px)`, //
        }}
      >
        <Box className={`${styles.swipe} ${styles.swipeLeftBox}`}>
          <SwipeButton isError={false} onClick={handleSwipe} disabled={cargandoPrimerLote} />
        </Box>

        {cargandoPrimerLote? <div style={{width:"100%", textAlign:"center"}}><CircularProgress/></div>: <Box className={styles.mesas}>
          <MesasStack mesasDataArray={mesaDataArray} />
        </Box>}

        <Box className={`${styles.swipe} ${styles.swipeRightBox}`}>
          <SwipeButton isError={true} onClick={handleSwipe} disabled={cargandoPrimerLote} />
        </Box>
      </Container>
    </MainLayout>
  );
};

export default Mesas;

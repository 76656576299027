import React, { useEffect, useState } from 'react';
import {Container, Typography } from '@mui/material';
import MainLayout from '../../components/layout/MainLayout';

const MesasVerificadas = () => {


  return (
    <MainLayout>
      <Container maxWidth="md">
      <Typography variant='h1'>Mesas Verificadas</Typography>
        <Typography>Esta página está en construcción</Typography>
      </Container>
    </MainLayout>
  );
};

export default MesasVerificadas;

import axios from "axios";
import { auth } from "../firebase";


const host = window.location.hostname;
let baseURL = "http://localhost:8080";
if(host == "localhost"){
  baseURL = "http://localhost:8080";
}else if(host.includes("ngrok-free.app")){
  baseURL = "https://ce01-110-175-140-159.ngrok-free.app";
}else{
  baseURL = "https://api.pueblofiscal.com.ar" 
}


const api = axios.create({
  baseURL,
  headers: {'ngrok-skip-browser-warning': 'true'}
  // You can add other default configurations here
});

api.interceptors.request.use(
  async (config) => {
    // Check if the URL contains the keyword 'private'
    if (config.url.includes("private")) {
      const user = auth.currentUser;

      if (!user) {
        // User is not logged in, cancel the request
        const cancelSource = axios.CancelToken.source();
        config.cancelToken = cancelSource.token;
        cancelSource.cancel("Canceling request: no user logged in");
      } else {
        try {
          const token = await user.getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
          console.error("Error retrieving auth token", error);
          // Optionally, handle token retrieval error here
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Manejo general de errores en la respuesta (con flag si lo deberia hacer el service)
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (needToPassErrorToServiceFunction(error)) {
      // Modify the error object or add a flag
      error.isServiceSpecific = true;
    }
    return Promise.reject(error);
  }
);

function needToPassErrorToServiceFunction(error) {
  // Example: Check if the error response has a specific structure or code
  if (error.response && error.response.data) {
    const errorData = error.response.data;

    // Suppose your API sets a specific error code for errors
    // that should be handled by the service function
    if (errorData.code === "SPECIFIC_ERROR_CODE") {
      return true;
    }
  }
  return false;
}

export default api;

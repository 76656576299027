// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAsXyaZCJUJpOYyGjuU2aOw_BRrLdzkMwA",
    authDomain: "pueblo-fiscal.firebaseapp.com",
    projectId: "pueblo-fiscal",
    storageBucket: "pueblo-fiscal.appspot.com",
    messagingSenderId: "355934976574",
    appId: "1:355934976574:web:ead07f0df922c45d9d6f6f",
    measurementId: "G-NDEY0S0YT4"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firebase Analytics (optional, only if you need analytics)
const analytics = getAnalytics(app);

export { app, auth, analytics };

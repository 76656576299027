import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const WrapInCarouselIfTrue = ({ condition, children }) => {
  const responsive = {
    all: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  return condition ? (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      infinite={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType="mobile"
    >
      {children}
    </Carousel>
  ) : (
    <>{children}</>
  );
};

export default WrapInCarouselIfTrue;

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

const MesaTablaOtros = ({ resultados, className, isFirstGroup }) => {
  let dataToShow = [];
  if (isFirstGroup) {
    dataToShow = [
      { label: "Nulos", value: resultados.nulos },
      { label: "Recurridos", value: resultados.recurridos },
      { label: "Impugnados", value: resultados.impugnados },
    ];
  } else {
    dataToShow = [
      { label: "Comando", value: resultados.comando },
      { label: "Blanco", value: resultados.blanco },
      { label: "TOTAL", value: resultados.total },
    ];
  }

  return (
    <TableContainer
      component={Paper}
      style={{ padding: "10px" }}
      className={className}
    >
      <Table aria-label="additional info table">
        <TableBody>
          <TableRow>
            <TableCell>{dataToShow[0].label}</TableCell>
            <TableCell align="right">{dataToShow[0].value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{dataToShow[1].label}</TableCell>
            <TableCell align="right">{dataToShow[1].value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: isFirstGroup ? "normal" : "bold" }}>
              {dataToShow[2].label}
            </TableCell>
            <TableCell
              style={{ fontWeight: isFirstGroup ? "normal" : "bold" }}
              align="right">
              {dataToShow[2].value}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MesaTablaOtros;

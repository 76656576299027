// src/components/SwipeButton.js

import React from "react";
import { IconButton, Typography } from "@mui/material";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DangerousTwoToneIcon from "@mui/icons-material/DangerousTwoTone";
import { useTheme } from "@mui/material/styles";
import styles from './SwipeButton.module.css'

const SwipeButton = ({ isError, onClick, disabled }) => {
  const theme = useTheme();
  console.log("STYLES", styles.root);

  const sxStyles = {
    // You can adjust these values to suit your design
    root: {
      boxShadow: 2,
      "&:active": {
        boxShadow: 1, // Shadow for "pressed" effect, less prominent than normal
        transform: "translateY(2px)", // Optional: slightly move the button down
      },
    }
  };

  return (
    <div style={{textAlign:'center'}}>
      <IconButton onClick={() => onClick(isError)} sx={sxStyles.root} className={styles.root} disabled={disabled}>
        {!isError && (
          <CheckCircleTwoToneIcon className={styles.icon} color={disabled? "grey": "success"} />
        )}
        {isError && (
          <DangerousTwoToneIcon className={styles.icon} color={disabled? "grey":"error"} />
        )}
      </IconButton>
      <Typography className={styles.texto} >{isError?"Hay Error":"Está OK"}</Typography>
      <Typography className={styles.tecla} >{isError?"[ENTER]":"[BARRA ESPACIADORA]"}</Typography>
    </div>
  );
};

export default SwipeButton;

import Home from "./pages/Home/Home";
import Mesas from "./pages/Mesas/Mesas";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { logIn, logOut } from "./redux/authSlice";
import Register from "./pages/Register/Register";
import ProtectedRoute from "./router/ProtectedRoute";
import NonLoggedInRoute from "./router/NonLoggedInRoute";
import { useTheme } from "@emotion/react";
import { getUserData } from "./services/userService";
import { userActions } from "./redux/userSlice";
import Estadisticas from "./pages/Estadisticas/Estadisticas";
import MesasVerificadas from "./pages/MesasVerificadas/MesasVerificadas";
import Tabla from "./pages/Tabla/Tabla";
import Perfil from "./pages/Perfil/Perfil";

function App() {
  const dispatch = useDispatch();
  const theme = useTheme();

  // AUTH Subscription
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth changed");
      if (user) {
        console.log("AUTH LIST USER", user);
        const userServiceRes = await getUserData();
        if(!userServiceRes.error){
          dispatch(userActions.setUserData(userServiceRes.response))
        }
        dispatch(logIn());
      } else {
        console.log("Usuario cerro sesion");
        dispatch(logOut());
        dispatch(userActions.deleteUserData())
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  // Styles so they can be accessed in CSS files
  useEffect(() => {
    // Set primary color
    document.documentElement.style.setProperty('--primary-color', theme.palette.primary.main);
    document.documentElement.style.setProperty('--primary-dark', theme.palette.primary.dark);
    document.documentElement.style.setProperty('--primary-contrast', theme.palette.primarySoftContrast.main);
    document.documentElement.style.setProperty('--primary-contrast-dark', theme.palette.primarySoftContrast.dark);

    // Set secondary color
    document.documentElement.style.setProperty('--secondary-color', theme.palette.secondary.main);
    document.documentElement.style.setProperty('--secondary-dark', theme.palette.secondary.dark);

    // You can add more theme colors similarly
    // For example, error, warning, info, success colors
    document.documentElement.style.setProperty('--error-color', theme.palette.error.main);
    document.documentElement.style.setProperty('--warning-color', theme.palette.warning.main);
    document.documentElement.style.setProperty('--info-color', theme.palette.info.main);
    document.documentElement.style.setProperty('--success-color', theme.palette.success.main);
    document.documentElement.style.setProperty('--text-primary', theme.palette.text.primary);
    document.documentElement.style.setProperty('--text-secondary', theme.palette.text.secondary);

    // You can also add other theme properties like typography, spacing, etc.
    document.documentElement.style.setProperty('--mobile-breakpoint', "768px");
    
  }, [theme]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/estadisticas" element={<Estadisticas />} />
        <Route path="/mesas-verificadas" element={<MesasVerificadas />} />
        <Route path="/tabla" element={<Tabla />} />
        <Route
          path="/mesas"
          element={
            <ProtectedRoute>
              <Mesas />
            </ProtectedRoute>
          }
        />
        <Route
          path="/perfil"
          element={
            <ProtectedRoute>
              <Perfil />
            </ProtectedRoute>
          }
        />
        <Route
          path="/crear-cuenta"
          element={
            <NonLoggedInRoute>
              <Register crearCuenta={true} />
            </NonLoggedInRoute>
          }
        />
        <Route
          path="/iniciar-sesion"
          element={
            <NonLoggedInRoute>
              <Register crearCuenta={false} />
            </NonLoggedInRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

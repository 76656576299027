import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useSelector(state => state.auth.loggedIn);
    const location = useLocation();

    // State to track if waiting period is over
    const [waitingPeriodOver, setWaitingPeriodOver] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            // Set a timeout for 3 seconds
            const timer = setTimeout(() => {
                setWaitingPeriodOver(true);
            }, 3000);

            // Cleanup function to clear the timer if the component unmounts
            return () => clearTimeout(timer);
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        if (!waitingPeriodOver) {
            // Show the message while waiting
            return <CircularProgress />;
        } else {
            // Redirect after 3 seconds if still not authenticated
            return <Navigate to="/iniciar-sesion" state={{ from: location }} replace />;
        }
    } else {
        return children;
    }
};

export default ProtectedRoute;

export const LAYOUT_DIMENSIONS = {
    headerHeight: 64, // pixels
    footerHeight: 56, // pixels
    headerBottomMargin: 16, // pixels
    bottomMargin: 16, // pixels
    mesaCardMargin: 16,// pixels
  };

export const SWIPING_DIR = {
  left: "left",
  right: "right"
}

export const REQ_MESAS_MODOS = {
  inicial: "inicial",
  anexo: "anexo",
  lote: "lote"
}

export const SITIO_OFICIAL_SUBDOMINIOS = {
  generales: "generales.",
  ballotage: "",
  current: "generales."
}

export const MOBILE_BREAKPOINT = 768;
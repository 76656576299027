import React, { useEffect, useState } from 'react';
import {Container, Typography } from '@mui/material';
import MainLayout from '../../components/layout/MainLayout';

const Home = () => {


  return (
    <MainLayout>
      <Container maxWidth="md">
      <Typography variant='h1'>HOME</Typography>
      <Typography style={{marginTop:"20px"}}><b>MODO TEST</b>: Crear cuenta y evaluar mesas dependiendo si los numeros del sistema matchean con los numeros en el telegrama</Typography>
      </Container>
    </MainLayout>
  );
};

export default Home;